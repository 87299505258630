import { PROVIDER } from '~/constants/groupTypes';

type Type = {
  apiName: string;
  displayName: string;
};

export interface GroupTypeOptions {
  id: string;
  apiName: string;
  displayName: string;
  name: string;
  isInpatient: boolean;
  dischargeReason: string | null;
  dischargeReasonVisibleGlobally: boolean;
  config: Record<string, unknown>;
  createdAt: string | null;
  updatedAt: string | null;
  type: Type;
}

const defaults: GroupTypeOptions = {
  id: '',
  apiName: '',
  displayName: '',
  name: '',
  isInpatient: false,
  dischargeReason: '',
  dischargeReasonVisibleGlobally: false,
  config: {},
  createdAt: null,
  updatedAt: null,
  type: {
    apiName: '',
    displayName: '',
  },
};

export default class GroupType {
  id: string;
  apiName: string;
  displayName: string;
  name: string;
  isInpatient: boolean;
  dischargeReason: string | null;
  dischargeReasonVisibleGlobally: boolean;
  config: Record<string, unknown>;
  createdAt: string | null;
  updatedAt: string | null;
  type: Type;

  constructor(options: Partial<GroupTypeOptions> = defaults) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.apiName = opts.apiName;
    this.displayName = opts.displayName;
    this.name = opts.name;
    this.isInpatient = opts.isInpatient;
    this.dischargeReason = opts.dischargeReason;
    this.dischargeReasonVisibleGlobally = opts.dischargeReasonVisibleGlobally;
    this.config = opts.config;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.type = opts.type as Type;
  }

  get isProvider() {
    return this.type.apiName === PROVIDER;
  }
}
