import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

const formStyles = css`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '500px'};
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  ${formStyles};
`;

const Form = styled(FormikForm)`
  ${formStyles};
`;

export default Form;
