import React from 'react';
import styled from 'styled-components';

import { PERMISSIONS, useProfileContext } from '~/services/profile';

import SideNavLink from './SideNavLink';

const SideNavbar = () => {
  const profileSvc = useProfileContext();
  const {
    adminAttrView,
    adminClassificationView,
    adminClientView,
    adminFeatureFlagView,
    adminGroupView,
    adminGroupTypeView,
    adminImportedPatientView,
    adminQuestionTemplateView,
    adminUserView,
  } = PERMISSIONS;

  const handleQuestionTemplatesIsActive = (title, pathname) =>
    title === 'Question Templates' && pathname.includes('/question-templates/');

  return (
    <NavContainer>
      <NavContent>
        <SideNavLink title='Clients' to='/clients' visible={profileSvc.has(adminClientView)} />
        <SideNavLink title='Users' to='/users' visible={profileSvc.has(adminUserView)} />
        <SideNavLink title='Groups' to='/groups' visible={profileSvc.has(adminGroupView)} />
        <SideNavLink title='Group Types' to='/group-types' visible={profileSvc.has(adminGroupTypeView)} />
        <SideNavLink title='Attributes' to='/attributes' visible={profileSvc.has(adminAttrView)} />
        <SideNavLink
          relativeIsActiveHandler={handleQuestionTemplatesIsActive}
          title='Question Templates'
          to='/question-templates/progress'
          visible={profileSvc.has(adminQuestionTemplateView)}
        />
        <SideNavLink
          title='Plan Types'
          to='/classifications/plan-type'
          visible={profileSvc.has(adminClassificationView)}
        />
        <SideNavLink
          title='Episode Types'
          to='/classifications/episode'
          visible={profileSvc.has(adminClassificationView)}
        />
        <SideNavLink title='Feature Flags' to='/feature-flags' visible={profileSvc.has(adminFeatureFlagView)} />
        <SideNavLink title='Import History' to='/import-history' visible={profileSvc.has(adminImportedPatientView)} />
      </NavContent>
    </NavContainer>
  );
};

export default SideNavbar;

const NavContainer = styled.div`
  min-height: calc(100vh - 2 * ${({ theme }) => theme.dimensions.mainHeaderHeight});
  min-width: ${({ theme }) => theme.dimensions.sideNavWidth};
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex: 0;
`;

const NavContent = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
