import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import { Attributes } from '~/components/attributes';
import EditAttr from '~/components/attributes/edit/EditAttr';
import EditAttrValue from '~/components/attributes/EditAttrValue';
import { Classifications } from '~/components/classifications';
import { Clients, EditClient } from '~/components/clients';
import { ConfigureCareOptions } from '~/components/clients/configureCareOptions';
import { EditImportConfiguration } from '~/components/clients/imports';
import { EditFeatureFlag, FeatureFlags } from '~/components/featureFlags';
import EditGroupType from '~/components/groupTypes/EditGroupType';
import GroupTypes from '~/components/groupTypes/GroupTypes';
import { StatusDetails } from '~/components/imports';
import ImportHistory from '~/components/imports/history/ImportHistory';
import NotificationPreferencesFormWrapper from '~/components/preferences/notifications/NotificationPreferencesFormWrapper';
import { QuestionTemplates, TemplateFormContainer } from '~/components/questionTemplates';
import CircleSpinner from '~/components/shared/CircleSpinner';
import { getProfile } from '~/ducks/profile';
import { useSwitch } from '~/lib/hooks';
import { Profile } from '~/models';
import { PERMISSIONS, ProfileContext, ProfileService } from '~/services/profile';

import { EditGroup, Groups } from '../groups';
import HeaderNavbar from '../layouts/HeaderNavbar';
import SideNavbar from '../layouts/SideNavbar';
import { ProtectedRoute } from '../router';
import Toasts from '../shared/toasts/toasts';
import { EditUser, Users } from '../users';
import { UserPermissionsContainer } from '../users/permissions';

import ClientSelectModal from './ClientSelectModal';

const Home = (props) => {
  const { history, profile } = props;
  const isProfileLoaded = !!profile.id;
  const [profileSvc, setProfileSvc] = useState(new ProfileService(profile));
  const {
    state: showClientSelectModal,
    turnOff: turnOffClientSelectModal,
    turnOn: turnOnClientSelectModal,
  } = useSwitch();

  const acceptedTermsOfServiceCheck = () => {
    if (isProfileLoaded && !profile.acceptedTermsOfService) {
      history.push('/agreements');
    }
  };

  const identifyUser = (userProfile) => {
    // send user data for gtm and appcues
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'profile updated',
      user: {
        id: userProfile.id,
        email: userProfile.email,
        name: userProfile.name,
        client: userProfile.client.name,
        clientType: userProfile.client.clientType,
        enabledProviderTypes: userProfile.enabledProviderTypes?.map((type) => type.apiName),
        role: userProfile.role,
        groupType: userProfile.groupType,
        userType: userProfile.isAcute ? 'acute' : 'post-acute',
        createdAtDate: userProfile.createdAt,
        lastLogin: userProfile.lastLogin,
        actingClient: userProfile.actingClient.name,
      },
    });
  };

  useEffect(acceptedTermsOfServiceCheck, [isProfileLoaded, profile]);
  useEffect(() => {
    const profileService = new ProfileService(profile);

    Sentry.setContext('login-info', {
      date: new Date(),
    });
    Sentry.setUser({ id: profileService.profile.id });

    datadogRum.setUser({
      id: profileService.profile.id,
      email: profileService.profile.email,
      clientId: profileService.client.id,
      clientName: profileService.client.name,
      actingClientId: profileService.actingClient.id,
      actingClientName: profileService.actingClient.name,
    });

    identifyUser(profileService.profile);

    setProfileSvc(profileService);
  }, [profile]);

  if (!isProfileLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <ProfileContext.Provider value={profileSvc}>
      <div>
        <HeaderNavbar onSelectClientClick={turnOnClientSelectModal} />
        <ClientSelectModal isOpen={showClientSelectModal} onCancel={turnOffClientSelectModal} history={history} />
        <MainContainer>
          <Toasts />
          <SideNavbar />
          <ContentContainer id='mainScrollContainer'>
            <Switch>
              <ProtectedRoute path='/groups/new' component={EditGroup} permissions={PERMISSIONS.adminGroupCreate} />
              <ProtectedRoute path='/groups/:id/edit' component={EditGroup} permissions={PERMISSIONS.adminGroupEdit} />
              <ProtectedRoute path='/groups' component={Groups} permissions={PERMISSIONS.adminGroupView} />
              <ProtectedRoute
                path='/group-types/new'
                component={EditGroupType}
                permissions={PERMISSIONS.adminGroupTypeCreate}
              />
              <ProtectedRoute
                path='/group-types/:id/edit'
                component={EditGroupType}
                permissions={PERMISSIONS.adminGroupTypeEdit}
              />
              <ProtectedRoute path='/group-types' component={GroupTypes} permissions={PERMISSIONS.adminGroupTypeView} />
              <ProtectedRoute
                path='/feature-flags/:name/edit'
                component={EditFeatureFlag}
                permissions={PERMISSIONS.adminFeatureFlagEdit}
              />
              <ProtectedRoute
                path='/feature-flags'
                component={FeatureFlags}
                permissions={PERMISSIONS.adminFeatureFlagView}
              />
              <ProtectedRoute
                path='/clients/import-configurations/new'
                component={EditImportConfiguration}
                permissions={PERMISSIONS.adminImportConfigurationCreate}
              />
              <ProtectedRoute
                path='/clients/import-configurations/:id/edit'
                component={EditImportConfiguration}
                permissions={PERMISSIONS.adminImportConfigurationEdit}
              />
              <ProtectedRoute path='/clients/new' component={EditClient} permissions={PERMISSIONS.adminClientCreate} />
              <ProtectedRoute
                path='/clients/:id/edit'
                component={EditClient}
                permissions={PERMISSIONS.adminClientEdit}
              />
              <ProtectedRoute
                path='/clients/:id/group-types/edit'
                component={ConfigureCareOptions}
                permissions={PERMISSIONS.adminClientGroupTypeView}
              />
              <ProtectedRoute
                path='/clients/collaborations'
                component={Clients}
                permissions={PERMISSIONS.adminCollaborationView}
              />
              <ProtectedRoute
                path='/clients/import-configurations'
                component={Clients}
                permissions={PERMISSIONS.adminImportConfigurationView}
              />
              <ProtectedRoute path='/clients' component={Clients} permissions={PERMISSIONS.adminClientView} />
              <ProtectedRoute
                path='/classifications/:type'
                component={Classifications}
                permissions={PERMISSIONS.adminClassificationView}
              />
              <ProtectedRoute path='/users/new' component={EditUser} permissions={PERMISSIONS.adminUserCreate} />
              <ProtectedRoute path='/users/:id/edit' component={EditUser} permissions={PERMISSIONS.adminUserEdit} />
              <ProtectedRoute
                path='/users/:id/preferences'
                component={NotificationPreferencesFormWrapper}
                permissions={PERMISSIONS.adminUserView}
              />
              <ProtectedRoute
                path='/users/:id/permissions'
                component={UserPermissionsContainer}
                permissions={PERMISSIONS.adminUserEdit}
              />
              <ProtectedRoute path='/users' component={Users} permissions={PERMISSIONS.adminUserView} />
              <ProtectedRoute
                path='/question-templates/:type/new'
                component={TemplateFormContainer}
                permissions={PERMISSIONS.adminQuestionTemplateCreate}
              />
              <ProtectedRoute
                path='/question-templates/:type/:id/edit'
                component={TemplateFormContainer}
                permissions={PERMISSIONS.adminQuestionTemplateEdit}
              />
              <ProtectedRoute
                path='/question-templates/progress'
                component={QuestionTemplates}
                permissions={PERMISSIONS.adminQuestionTemplateView}
              />
              <ProtectedRoute
                path='/question-templates/discharge'
                component={QuestionTemplates}
                permissions={PERMISSIONS.adminQuestionTemplateView}
              />
              <ProtectedRoute
                path='/import-history/:id/status-details'
                component={StatusDetails}
                permissions={PERMISSIONS.adminImportedPatientView}
              />
              <ProtectedRoute
                path='/import-history'
                component={ImportHistory}
                permissions={PERMISSIONS.adminImportedPatientView}
              />
              <ProtectedRoute
                path='/attributes/values/new'
                component={EditAttrValue}
                permissions={PERMISSIONS.adminAttrValueCreate}
              />
              <ProtectedRoute
                path='/attributes/values/:id/edit'
                component={EditAttrValue}
                permissions={PERMISSIONS.adminAttrValueEdit}
              />
              <ProtectedRoute path='/attributes/new' component={EditAttr} permissions={PERMISSIONS.adminAttrCreate} />
              <ProtectedRoute
                path='/attributes/:id/edit'
                component={EditAttr}
                permissions={PERMISSIONS.adminAttrEdit}
              />
              <ProtectedRoute path='/attributes' component={Attributes} permissions={PERMISSIONS.adminAttrView} />
              <ProtectedRoute
                path='/attributes/values'
                component={Attributes}
                permissions={PERMISSIONS.adminAttrValueView}
              />
              <Redirect from='/' to='/clients' />
            </Switch>
          </ContentContainer>
        </MainContainer>
      </div>
    </ProfileContext.Provider>
  );
};

Home.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Profile).isRequired,
};

const mapStateToProps = (state) => ({
  profile: getProfile(state),
});

export default connect(mapStateToProps)(Home);

const mainHeaderNavHeight = 48;
const MainContainer = styled.div.attrs({
  headerNavHeight: mainHeaderNavHeight,
})`
  display: flex;
  min-height: calc(100vh - ${({ headerNavHeight }) => headerNavHeight + 'px'});
`;

const ContentContainer = styled.div.attrs({
  headerNavHeight: mainHeaderNavHeight,
})`
  flex: 1;
  background: ${({ theme }) => theme.colors.black05};
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - ${({ headerNavHeight }) => headerNavHeight + 'px'});
  position: relative;
`;
