import { Profile } from '~/models';

export const PERMISSIONS = {
  adminAttrView: 'adminAttrView',
  adminAttrCreate: 'adminAttrCreate',
  adminAttrEdit: 'adminAttrEdit',
  adminAttrValueView: 'adminAttrValueView',
  adminAttrValueCreate: 'adminAttrValueCreate',
  adminAttrValueEdit: 'adminAttrValueEdit',
  adminClassificationCreate: 'adminClassificationCreate',
  adminClassificationEdit: 'adminClassificationEdit',
  adminClassificationView: 'adminClassificationView',
  adminClientCreate: 'adminClientCreate',
  adminClientEdit: 'adminClientEdit',
  adminClientView: 'adminClientView',
  adminClientGroupTypeEdit: 'adminClientGroupTypeEdit',
  adminClientGroupTypeView: 'adminClientGroupTypeView',
  adminCollaborationEdit: 'adminCollaborationEdit',
  adminCollaborationView: 'adminCollaborationView',
  adminFeatureFlagEdit: 'adminFeatureFlagEdit',
  adminFeatureFlagView: 'adminFeatureFlagView',
  adminGroupCreate: 'adminGroupCreate',
  adminGroupDelete: 'adminGroupDelete',
  adminGroupEdit: 'adminGroupEdit',
  adminGroupView: 'adminGroupView',
  adminGroupTypeCreate: 'adminGroupTypeCreate',
  adminGroupTypeEdit: 'adminGroupTypeEdit',
  adminGroupTypeView: 'adminGroupTypeView',
  adminImportedPatientEdit: 'adminImportedPatientEdit',
  adminImportedPatientView: 'adminImportedPatientView',
  adminImportConfigurationCreate: 'adminImportConfigurationCreate',
  adminImportConfigurationEdit: 'adminImportConfigurationEdit',
  adminImportConfigurationView: 'adminImportConfigurationView',
  adminQuestionTemplateCreate: 'adminQuestionTemplateCreate',
  adminQuestionTemplateView: 'adminQuestionTemplateView',
  adminQuestionTemplateEdit: 'adminQuestionTemplateEdit',
  adminUserCreate: 'adminUserCreate',
  adminUserEdit: 'adminUserEdit',
  adminUserInvitationCreate: 'adminUserInvitationCreate',
  adminUserDelete: 'adminUserDelete',
  adminUserView: 'adminUserView',
  activityNoteCreate: 'activityNoteCreate',
  activityProgressUpdateCreate: 'activityProgressUpdateCreate',
  escalationCreate: 'escalationCreate',
  escalationEdit: 'escalationEdit',
  locationEpisodeUserEdit: 'locationEpisodeUserEdit',
  patientCreate: 'patientCreate',
  patientDelete: 'patientDelete',
  patientEdit: 'patientEdit',
  rehabStateAdmissionCreate: 'rehabStateAdmissionCreate',
  rehabStateDischargeCreate: 'rehabStateDischargeCreate',
  rehabStateEdit: 'rehabStateEdit',
  reviewAuthorizationEdit: 'reviewAuthorizationEdit',
  reviewProjectedDischargeEdit: 'reviewProjectedDischargeEdit',
  reviewServiceRefusalCreate: 'reviewServiceRefusalCreate',
  reviewServiceRefusalEdit: 'reviewServiceRefusalEdit',
  reviewAltcsApplicationEdit: 'reviewAltcsApplicationEdit',
};

export default class ProfileService {
  constructor(profile = new Profile()) {
    this.profile = profile;
  }

  get actingClient() {
    return this.profile.actingClient;
  }

  get client() {
    return this.profile.client;
  }

  get clientType() {
    return this.actingClient?.clientType;
  }

  get enabledProviderTypes() {
    return this.profile.enabledProviderTypes;
  }

  get isAcute() {
    return this.profile.isAcute;
  }

  get isPostAcute() {
    return !this.isAcute;
  }

  get isAdmin() {
    return this.profile.isAdmin;
  }

  get isInpatientPostAcute() {
    return this.profile.isInpatientPostAcute;
  }

  get isOutpatientPostAcute() {
    return this.profile.isOutpatientPostAcute;
  }

  get groupType() {
    return this.profile.groupType;
  }

  has(permission) {
    if (!this.profile.permissions) return false;

    return this[permission] || this.profile.permissions[permission] || false;
  }

  hasAll(permissions) {
    return permissions.every((permission) => this.has(permission));
  }

  hasAny(permissions) {
    return permissions.some((permission) => this.has(permission));
  }

  hasFlag(name) {
    if (!this.profile.flags) return false;

    return this.profile.flags.includes(name);
  }
}
