import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import {
  Checkbox,
  FormSection,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader
} from '~/components/shared/form';
import { PLAN_TYPE } from '~/constants/classifications';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { Client } from '~/models';
import colors from '~/styles/theme/colors';

import { ReducedConfigs } from './types';

function ConfigureCareOptionsForm(props: ConfigureCareOptionsFormProps) {
  const { selectedTabValue, client } = props;

  const {
    setFieldValue,
    status: { disabled },
    values
  } = useFormikContext<ReducedConfigs>();

  const commonSelectProps = {
    closeMenuOnSelect: false,
    getOptionLabel: getName,
    getOptionValue: getId,
    isClearable: true
  };

  const commonMultiSelectProps = {
    ...commonSelectProps,
    component: MultiSelect,
    labelComponent: MultiSelectLabel
  };

  const planTypeClassificationsFieldName = `${selectedTabValue}.utilizationManagement.planTypeClassification`;
  const umCheckboxFieldName = `${selectedTabValue}.utilizationManagement.enabled`;
  const pddCheckboxFieldName = `${selectedTabValue}.projectedDischarge.enabled`;

  const planTypesAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: client?.id,
    params: { type: PLAN_TYPE, client: client.id }
  });

  const handleUmEnabledChange = (enabled: boolean) => {
    if (!enabled) {
      setFieldValue(planTypeClassificationsFieldName, []);
    }
  };

  // early return out of component if no active tab
  // or no form values to prevent rendering errors
  if (!selectedTabValue || JSON.stringify(values) === '{}') return null;
  const visible = values[selectedTabValue].utilizationManagement.enabled;

  return (
    <Container>
      <SectionHeader>Enabled Features</SectionHeader>
      <StyledFormSection>
        <StyledCheckBox name={ umCheckboxFieldName }
          label='UM Authorization Enabled'
          labelSize='14px'
          data-cy='umEnabledConfigureCareOptionsCheckbox'
          checkedColor={ colors.primaryBlue }
          disabled={ disabled }
          onChange={ handleUmEnabledChange }
          size={ 14 } />
        <InputGroup { ...commonMultiSelectProps }
          { ...planTypesAsyncOptions }
          name={ planTypeClassificationsFieldName }
          data-cy='umConfigureCareOptionsDropdown'
          label='UM Enabled Plan Types'
          disabled={ disabled }
          placeholder='All Plan Types'
          visible={ visible }
          selectedCountDefault='All' />
        <StyledCheckBox name={ pddCheckboxFieldName }
          label='Projected Discharge Enabled'
          labelSize='14px'
          data-cy='pddConfigureCareOptionsCheckbox'
          checkedColor={ colors.primaryBlue }
          disabled={ disabled }
          size={ 14 } />
      </StyledFormSection>
    </Container>
  );
}

ConfigureCareOptionsForm.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
  selectedTabValue: PropTypes.string
};

type ConfigureCareOptionsFormProps = InferProps<typeof ConfigureCareOptionsForm.propTypes>;

export default ConfigureCareOptionsForm;

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${ (props) => props.theme.colors.black10 };
  background-color: ${ (props) => props.theme.colors.white };
  padding: 24px;
  width: 500px;
`;

const StyledFormSection = styled(FormSection)`
  display: flex;
  flex-direction: column;
`;

const StyledCheckBox = styled(Checkbox)`
  margin-top: 8px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
