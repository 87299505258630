import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { unwrapResult } from '@reduxjs/toolkit';

import { GROUP_TYPE_TYPES } from '~/constants/groupTypes';
import {
  createGroupType,
  CreateGroupTypeAPIParams,
  fetchGroupType,
  updateGroupType,
  UpdateGroupTypeAPIParams,
} from '~/ducks/admin/groupTypes';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';

import CircleSpinner from '../shared/CircleSpinner';
import { FormPage } from '../shared/pageLayout';

import GroupTypeForm, { GroupTypeFormProps, GroupTypeFormValues } from './GroupTypeForm';
import { groupTypeFormValidation } from './groupTypeFormValidation';

function EditGroupType(props: Props) {
  const groupTypeId = props.match.params.id;

  const { data: groupType, loaded: groupTypeLoaded } = useThunk(fetchGroupType, [groupTypeId], {
    condition: !!groupTypeId,
    params: groupTypeId,
  });

  const navigateToGroupTypes = () => {
    props.history.push('/group-types');
  };

  const handleSubmit = (values: GroupTypeFormValues, { setSubmitting }: FormikHelpers<GroupTypeFormValues>) => {
    const request = groupTypeId ? props.updateGroupType : props.createGroupType;
    const valuesForRequest: CreateGroupTypeAPIParams | UpdateGroupTypeAPIParams = {
      ...values,
      id: groupTypeId,
      type: values.type?.value,
      dischargeReason: values.dischargeReason?.trim() || null,
    };

    return request(valuesForRequest)
      .then(unwrapResult)
      .catch((error: unknown) => {
        props.addToast({
          text: `There was an error ${groupTypeId ? 'updating' : 'creating'} the group type. Please try again.`,
        });
        throw error;
      })
      .then(navigateToGroupTypes)
      .then(() => props.addToast({ text: `Group type successfully ${groupTypeId ? 'updated' : 'added'}!` }))
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(() => {
    const type = GROUP_TYPE_TYPES.find((type) => type.value === groupType?.type?.apiName);

    return {
      enableReinitialize: true,
      handleSubmit,
      validationSchema: groupTypeFormValidation,
      mapPropsToValues: () => ({
        ...groupType,
        // override the dischargeReasonVisibleGlobally property when creating a new group type
        // so that a value is not initially selected
        dischargeReasonVisibleGlobally: groupTypeId ? groupType?.dischargeReasonVisibleGlobally : null,
        type,
      }),
    };
  }, [groupType]);

  const FormikGroupTypeForm = useMemo(
    () => withFormik<GroupTypeFormProps, GroupTypeFormValues>(formikOptions)(GroupTypeForm),
    [formikOptions]
  );

  if (!!groupTypeId && !groupTypeLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikGroupTypeForm onCancel={navigateToGroupTypes} isEditing={!!groupTypeId} />
    </FormPage>
  );
}

EditGroupType.propTypes = {
  addToast: PropTypes.func.isRequired,
  createGroupType: PropTypes.func.isRequired,
  updateGroupType: PropTypes.func.isRequired,
};

type RouteProps = RouteComponentProps<{ id?: string }>;
type Props = InferProps<typeof EditGroupType.propTypes> & RouteProps;

const mapDispatchToProps = {
  addToast,
  createGroupType,
  updateGroupType,
};

export default connect(null, mapDispatchToProps)(EditGroupType);
