import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { isDeepEqual } from '~/lib';
import useField from '~/lib/hooks/useField';

type Option = {
  label: string;
  value: any;
  icon?: React.ReactNode;
};

type Props = {
  name: string;
  options: Option[];
  defaultValue?: Option['value'];
  onValueChange?: (value: Option['value']) => void;
};

export default function ToggleSelector({ name, options, onValueChange, defaultValue }: Props) {
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (defaultValue) {
      helpers.setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (value: Option['value']) => {
    helpers.setValue(value);
    onValueChange?.(value);
  };

  return (
    <ButtonGroup data-cy='toggle-selector'>
      {options.map((option, index) => (
        <SelectorButton
          key={index}
          text={option.label}
          color='gray'
          icon={option.icon}
          selected={isDeepEqual(option.value, field.value)}
          onClick={() => handleChange(option.value)}
        />
      ))}
    </ButtonGroup>
  );
}

const selectedStyles = css`
  background-color: ${({ theme }) => theme.colors.primaryBlue10};
  border-color: ${({ theme }) => theme.colors.primaryBlue};
  color: ${({ theme }) => theme.colors.primaryBlue};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryBlue10};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.primaryBlue};
    background-color: ${({ theme }) => theme.colors.primaryBlue10};
    color: ${({ theme }) => theme.colors.primaryBlue};
  }
`;

const SelectorButton = styled(Button)`
  && {
    ${({ selected }) => selected && selectedStyles};
    border-width: 1px;
    font-size: 14px;
    line-height: 18px;
    flex: 1;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
