import React from 'react';
import PropTypes from 'prop-types';

import colors from '~/styles/theme/colors';

function Logo({ fill, width }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={ width } viewBox='0 0 540.18 247.43'>
      <title>Olio-logoAsset 12</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path fill={ fill } d='M90,247.43c-51.45,0-90-38.58-90-88.19S38.58,71,90,71s90,38.59,90,88.2S141.48,247.43,90,247.43Zm0-55.12c16.54,0,31.24-14.7,31.24-33.07S106.57,126.17,90,126.17,58.8,140.86,58.8,159.24,73.49,192.31,90,192.31Z' />
          <path fill={ fill } d='M257.23,243.76h-58.8V1.94h58.8Z' />
          <ellipse fill={ fill } cx='312.35' cy='30.72' rx='32.64' ry='30.72' />
          <rect fill={ fill } x='282.95' y='74.72' width='58.8' height='169.04' />
          <path fill={ fill } d='M450.15,247.43c-51.44,0-90-38.58-90-88.19s38.59-88.2,90-88.2,90,38.59,90,88.2S501.6,247.43,450.15,247.43Zm0-55.12c16.54,0,31.24-14.7,31.24-33.07s-14.7-33.07-31.24-33.07-31.23,14.69-31.23,33.07S433.62,192.31,450.15,192.31Z' />
        </g>
      </g>
    </svg>
  );
}

Logo.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number
};

Logo.defaultProps = {
  fill: colors.white,
  width: 70
};

export default Logo;
