import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { createClient, fetchClient, updateClient } from '~/ducks/admin/clients';
import { addToast } from '~/ducks/toasts';
import { capitalize, humanize } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';

import { FormPage } from '../shared/pageLayout';

import ClientForm from './ClientForm';
import validationSchema from './clientFormValidations';

function EditClient(props) {
  const id = props.match.params.id;
  const { data: client, loaded } = useThunk(fetchClient, [], {
    condition: !!id,
    params: {
      id,
      include: 'parent,children,episode_classifications,plan_type_classifications,group_types',
    },
  });

  const goToClients = () => props.history.push('/clients');

  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    const params = {
      id: values.id,
      name: values.name,
      clientType: values.clientType.name,
      parentId: values.parent?.id || null,
      episodeClassificationIds: (values.episodeClassifications || []).map((ec) => ec.id),
      planTypeClassificationIds: (values.planTypeClassifications || []).map((pc) => pc.id),
      groupTypeIds: (values.groupTypes || []).map((gt) => gt.id),
      mfaConfig: {
        enabled: values.mfaConfig.enabled,
        default: values.mfaConfig.default?.id || null,
        allowedFactors: values.mfaConfig.allowedIndependentFactors
          .concat(values.mfaConfig.allowedDependentFactors)
          .map((factor) => factor.id),
      },
      ssoEnabled: values.ssoEnabled,
    };

    const clientRequest = values.id ? props.updateClient : props.createClient;

    clientRequest(params)
      .then(unwrapResult)
      .then(goToClients)
      .catch((e) => {
        const errors = e.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.keys(errors).reduce((obj, key) => {
            obj[key] = errors[key].map((err) => `${capitalize(humanize(key))} ${err}`).join(', ');
            return obj;
          }, {});

          setErrors(errorMessages);
        } else {
          props.addToast({ text: 'Something went wrong. Please try again.' });
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToValues: () => ({ ...client.toFormValues() }),
      validationSchema,
    }),
    [client]
  );

  const FormikClientForm = useMemo(() => withFormik(formikOptions)(ClientForm), [formikOptions]);

  if (id && !loaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikClientForm onCancel={goToClients} />
    </FormPage>
  );
}

EditClient.propTypes = {
  addToast: PropTypes.func.isRequired,
  createClient: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createClient,
  updateClient,
};

export default connect(null, mapDispatchToProps)(EditClient);
