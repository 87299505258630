import { useState } from 'react';
import * as _ from 'lodash-es';

import { unwrapResult } from '~/lib';
import { store } from '~/store';

import useDeepEffect from './useDeepEffect';
import useModel from './useModel';

const useThunk = (thunk, dependencies = [], options = {}) => {
  const {
    params = {},
    initialData = thunk.defaultValue,
    model = thunk.modelClass,
    onError = () => {},
    onCompleted = () => {},
    onSuccess = () => {},
    parseData = (payload) => payload?.data || payload?.items || payload,
    condition = true,
    debounce = 0,
  } = options;

  if (!model) {
    throw new Error(
      'useThunk expects a "model" options parameter or the "thunk" parameter to have a "modelClass" property'
    );
  }

  const [data, setData] = useState(initialData);
  const [loaded, setLoaded] = useState(false);

  const handleCompleted = () => {
    onCompleted();
    setLoaded(true);
  };

  const invoke = () => {
    if (condition) {
      setLoaded(false);
      store
        .dispatch(thunk(params))
        .then(unwrapResult)
        .then((payload) => {
          setData(parseData(payload));
          onSuccess(payload);
        })
        .catch(onError)
        .finally(handleCompleted);
    }
  };

  const debouncedInvoke = _.debounce(invoke, debounce);

  useDeepEffect(debounce > 0 ? debouncedInvoke : invoke, dependencies);

  return { data: useModel(model, data), loaded, setData };
};

export default useThunk;
