import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { DeactivateIcon } from '~/components/shared/svg';
import { ParentLabel } from '~/components/shared/table';
import { BodySmall, BodySmallBold } from '~/components/shared/typography';
import { Group } from '~/models';
import { colors } from '~/styles/theme';

import GroupsFlyoutMenu, { permissions } from './GroupsFlyoutMenu';

const columnHelper = createColumnHelper<Group>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof GroupsFlyoutMenu>;

export default function groupsTableColumns({ profileSvc, ...groupsFlyoutMenuProps }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Group Name',
      cell: (info) => {
        const group = info.row.original;

        if (group.archived) {
          return (
            <React.Fragment>
              <DeactivateIcon fill={colors.black25} size={16} />
              <BodySmallBold color={colors.black25} margin='0 0 0 8px'>
                {info.getValue()}
              </BodySmallBold>
            </React.Fragment>
          );
        }

        return <BodySmallBold>{info.getValue()}</BodySmallBold>;
      },
    }),
    columnHelper.accessor((row) => row.client?.name, {
      id: 'clientName',
      header: 'Client',
      cell: (info) => {
        const isParent = !info.row.original.client?.leaf;

        return (
          <BodySmall>
            {info.getValue()}
            {isParent && <ParentLabel />}
          </BodySmall>
        );
      },
    }),
    columnHelper.accessor((row) => row.locationType.name, {
      id: 'type',
      header: 'Type',
    }),
    columnHelper.accessor((row) => row.address, {
      id: 'address',
      header: 'Address',
      cell: (info) => {
        if (!info.getValue()) return null;
        const street = info.getValue().streetAddress;
        const city = info.getValue().city;
        const state = info.getValue().state;

        return [street, city, state].filter((x) => x).join(', ');
      },
      enableSorting: false,
    }),
  ];

  if (profileSvc.hasAny(permissions)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => <GroupsFlyoutMenu {...groupsFlyoutMenuProps} row={info.row} />,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
