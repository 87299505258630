import {
  ACUTE_LOCATION_TYPES,
  AFFILIATE,
  HOSPITAL,
  PAYER,
  PHYSICIAN_GROUP,
  PHYSICIAN_TEAM,
} from '~/constants/locationTypes';
import Client from '~/models/Client';
import GroupType from '~/models/GroupType';

import LocationType from './LocationType';

const defaults = {
  id: '',
  archived: false,
  name: '',
  address: {
    streetAddress: '',
    streetAddressLine2: '',
    city: '',
    state: '',
    zip: '',
  },
  client: {},
  clientId: '',
  locationType: new LocationType(),
  groupType: new GroupType(),
  groupTypeId: '',
  timezone: '',
  type: '',
  groups: [],
  owners: [],
};

export default class Group {
  constructor(opts = {}) {
    const options = { ...defaults, ...opts };

    this.id = options.id;
    this.archived = options.archived;
    this.name = options.name;
    this.type = options.type;
    this.address = options.address;
    this.locationType = options.locationType;
    this.groupType = options.groupType ? new GroupType(options.groupType) : options.groupType;
    this.groupTypeId = options.groupTypeId;
    this.timezone = options.timezone;
    this.client = options.client ? new Client(options.client) : options.client;
    this.clientId = options.clientId;
    this._groups = options.groups;
    this._owners = options.owners;
  }

  serialize() {
    // eslint-disable-next-line no-unused-vars
    const { _groups, _owners, ...propsToSerialize } = this;

    return {
      ...propsToSerialize,
      groupIds: this.groups.map((group) => group.id),
      ownerIds: this.owners.map((group) => group.id),
    };
  }

  get isAcute() {
    return ACUTE_LOCATION_TYPES.includes(this.locationType.kind);
  }

  get isProvider() {
    return !!this.groupType?.isProvider;
  }

  get isHospital() {
    return this.locationType.kind === HOSPITAL;
  }

  get isPayer() {
    return this.locationType.kind === PAYER;
  }

  get isPhysicianGroup() {
    return this.locationType.kind === PHYSICIAN_GROUP;
  }

  get isPhysicianTeam() {
    return this.locationType.kind === PHYSICIAN_TEAM;
  }

  get isAffiliate() {
    return this.locationType.kind === AFFILIATE;
  }

  get groups() {
    return this._groups.map((group) => new Group(group));
  }

  get owners() {
    return this._owners.map((owner) => new Group(owner));
  }

  toFormValues() {
    return {
      ...this,
      address: {
        ...this.address,
        state: this.address?.state ? { label: this.address.state, value: this.address.state } : '',
      },
      client: this.client.id ? this.client : '',
      groups: this.groups,
      owners: this.owners,
      type: this.locationType.kind ? this.locationType : undefined,
      groupType: this.groupType?.id ? this.groupType : undefined,
    };
  }

  static fromFormValues(values) {
    const isAcuteClient = values.client?.isAcute;

    return new this({
      ...values,
      type: values.locationType?.kind,
      groupTypeId: values.groupType?.id,
      timezone: values.timezone.value,
      address: {
        ...values.address,
        state: values.address?.state?.value,
      },
      groups: [
        ...((isAcuteClient && values.hospitals) || []),
        ...(values.physicianTeams || []),
        ...(values.rehabFacilities || []),
      ],
      owners: [
        ...((!isAcuteClient && values.hospitals) || []),
        ...(values.physicianGroups || []),
        ...(values.payers || []),
      ],
    });
  }
}
