import * as Yup from 'yup';

export const groupTypeFormValidation =
  Yup.object().shape({
    name: Yup.string().required('Standard name is required'),
    displayName: Yup.string().required('Display name is required'),
    dischargeReason: Yup.string().nullable(),
    dischargeReasonVisibleGlobally: Yup.boolean(),
    isInpatient: Yup.boolean(),
    type: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string()
    }).nullable().test('type', 'Category is required', (obj) => Boolean(obj && obj.value)),
  });
