import moment from 'moment';

export const simpleDate = (date) => {
  const formatted = moment(date).format('MM/DD/YYYY');

  return formatted === 'Invalid date' ? null : formatted;
};

export const dateAtTime = (date) => {
  return moment(date).calendar(null, {
    lastWeek: 'MM/DD/YYYY [at] h:mm A',
    sameElse: 'MM/DD/YYYY [at] h:mm A'
  });
};

export const simpleDateAtTime = (date) => {
  const formatted = moment(date).format('MM/DD/YY [at] h:mm a');

  return formatted === 'Invalid date' ? null : formatted;
};

export const dateWithoutTimezone = (date) => {
  const formatted = moment.parseZone(date).format('MM/DD/YYYY');

  return formatted === 'Invalid date' ? null : formatted;
};
