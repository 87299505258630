import { ALLOWED_STATUS_ACTIONS, ImportAction, ImportStatus } from '~/constants/imports';
import { Attachment, Client, ImportStep } from '~/models';

interface IImport {
  readonly pending: boolean;
  readonly running: boolean;
  readonly paused: boolean;
  readonly completed: boolean;
  readonly clientName: string;
  readonly hasBeenRestarted: boolean;
}

type StepCount = {
  fileRowCount: number;
  memberCount: number;
};

interface IImportOptions {
  id: string;
  child: Import | null;
  clientId: string;
  client: Client;
  file: Attachment | null;
  status: string;
  statusMessage: string | null;
  stepCounts: StepCount;
  steps: ImportStep[];
  createdAt: string | null;
  updatedAt: string | null;
}

const defaults: IImportOptions = {
  id: '',
  child: null,
  clientId: '',
  client: new Client(),
  file: new Attachment(),
  status: ImportStatus.PENDING,
  statusMessage: null,
  stepCounts: {
    fileRowCount: 0,
    memberCount: 0,
  },
  steps: [],
  createdAt: null,
  updatedAt: null,
};

export default class Import implements IImport {
  id: string;
  child: Import | null;
  clientId: string;
  client: Client;
  file: Attachment | null;
  status: ImportStatus;
  statusMessage: string | null;
  stepCounts: StepCount;
  steps: ImportStep[];
  createdAt: string | null;
  updatedAt: string | null;
  constructor(options: IImportOptions) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.child = opts.child && new Import(opts.child as IImportOptions);
    this.clientId = opts.clientId;
    this.status = opts.status as ImportStatus;
    this.statusMessage = opts.statusMessage;
    this.steps = opts.steps && opts.steps.map((step) => new ImportStep(step));
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.client = opts.client ? new Client(opts.client) : opts.client;
    this.file = opts.file ? new Attachment(opts.file) : opts.file;
    this.stepCounts = opts.stepCounts;
  }

  get pending() {
    return this.status === ImportStatus.PENDING;
  }

  get running() {
    return this.status === ImportStatus.RUNNING;
  }

  get paused() {
    return this.status === ImportStatus.PAUSED;
  }

  get completed() {
    return this.status === ImportStatus.COMPLETED;
  }

  get errored() {
    return this.status === ImportStatus.ERRORED;
  }

  get clientName() {
    return this.client?.name;
  }

  get hasBeenRestarted() {
    return Boolean(this.child);
  }

  get runnable() {
    return ALLOWED_STATUS_ACTIONS.get(this.status)?.includes(ImportAction.RUN);
  }

  get restartable() {
    return !this.hasBeenRestarted && ALLOWED_STATUS_ACTIONS.get(this.status)?.includes(ImportAction.RESTART);
  }

  get cancellable() {
    return ALLOWED_STATUS_ACTIONS.get(this.status)?.includes(ImportAction.CANCEL);
  }
}
