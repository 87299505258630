import React from 'react';
import { FormikProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Checkbox, Form, FormHeader, FormSection, Input, InputGroup, Select } from '~/components/shared/form';
import ToggleSelector from '~/components/shared/ToggleSelector';
import { GROUP_TYPE_TYPES } from '~/constants/groupTypes';
import colors from '~/styles/theme/colors';

export type GroupTypeFormValues = {
  displayName: string;
  name: string;
  dischargeReason?: string;
  dischargeReasonVisibleGlobally?: boolean;
  isInpatient: boolean;
  type: { label: string; value: string };
};

export type GroupTypeFormProps = {
  isEditing: boolean;
  onCancel: () => void;
};

type Props = GroupTypeFormProps & FormikProps<GroupTypeFormValues>;

export default function GroupTypeForm({ onCancel, isEditing }: Props) {
  const { handleSubmit, isValid, dirty, isSubmitting } = useFormikContext();

  const options = [
    { label: 'Always display', value: true },
    { label: 'Only display if enabled', value: false },
  ];

  return (
    <Form>
      <FormHeader title={isEditing ? 'Edit Group Type' : 'Add Group Type'} />
      <FormSection>
        <InputGroup
          name='type'
          label='Category'
          placeholder='Category'
          options={GROUP_TYPE_TYPES}
          disabled={isEditing}
          component={Select}
        />

        <InputGroup name='name' label='Standard Name' component={Input} />

        <InputGroup name='displayName' label='Display Name' component={Input} />

        <StyledCheckbox
          name='isInpatient'
          label='Inpatient'
          checkedColor={colors.primaryBlue}
          labelSize='14px'
          size={20}
          disabled={isEditing}
        />

        <InputGroup name='dischargeReason' label='Discharge Reason (optional)' component={Input} />

        <InputGroup
          name='dischargeReasonVisibleGlobally'
          label='When should this discharge reason be displayed?'
          component={ToggleSelector}
          options={options}
        />
      </FormSection>

      <ButtonGroup>
        <Button color='transparent' onClick={onCancel} text='Cancel' />
        <Button
          onClick={handleSubmit}
          disabled={!dirty || !isValid || isSubmitting}
          text={isEditing ? 'Update Group Type' : 'Create Group Type'}
        />
      </ButtonGroup>
    </Form>
  );
}

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
`;
