import React from 'react';

const LoggedOutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={64} height={64} viewBox={'0 0 64 64'} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M29.3333 26.6666H58.6667' stroke='#3352EF' strokeWidth='4' strokeMiterlimit='10' />
    <path
      d='M48 16L58.6667 26.6667L48 37.3333'
      stroke='#3352EF'
      strokeWidth='4'
      strokeMiterlimit='10'
      strokeLinecap='square'
    />
    <path d='M34.6667 34.6666V45.3333H21.3333' stroke='#3352EF' strokeWidth='4' strokeMiterlimit='10' />
    <path
      d='M2.66667 5.33331L21.3333 18.7093V58.6666L2.66667 45.3333V5.33331ZM2.66667 5.33331H34.6667V18.6666'
      stroke='#3352EF'
      strokeWidth='4'
      strokeMiterlimit='10'
    />
  </svg>
);

export default LoggedOutIcon;
