import React, { createContext, useMemo, useState } from 'react';
import { withFormik } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { unwrapResult } from '@reduxjs/toolkit';

import { updateClientGroupType } from '~/ducks/admin/clientGroupTypes';
import { fetchClient } from '~/ducks/admin/clients';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';
import { GroupType } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import ConfigureCareOptionsForm from './ConfigureCareOptionsForm';
import ConfigureCareOptionsMainPage from './ConfigureCareOptionsMainPage';
import { ConfigureCareOptionsContext } from './context';
import { ReducedConfigs } from './types';

function ConfigureCareOptions(props: Props) {
  const [selectedTabValue, setSelectedTabValue] = useState('');

  const id = props.match.params.id;
  const profileSvc: any = useProfileContext();
  const formDisabled = !profileSvc.has(PERMISSIONS.adminClientGroupTypeEdit);
  const { data: client, setData: updateClient } = useThunk(fetchClient, [], {
    condition: !!id,
    params: {
      id,
      include: 'clientGroupTypes.groupType,group_types',
    },
  });

  const initialState = useMemo(
    () =>
      client.clientGroupTypes.reduce(
        (acc: ReducedConfigs, curr: GroupType) => ({
          ...acc,
          [curr.id]: curr.config,
        }),
        {}
      ),
    [client.clientGroupTypes]
  );

  const handleSubmit = async (values: ReducedConfigs) => {
    const updateRequests: any[] = [];

    Object.entries(values).map(([clientGroupTypeId, clientGroupTypeValues]) => {
      const params = {
        id: clientGroupTypeId,
        projected_discharge: clientGroupTypeValues.projectedDischarge,
        utilization_management: clientGroupTypeValues.utilizationManagement,
        include: 'groupType',
      };

      updateRequests.push(props.updateClientGroupType(params));
    });

    await Promise.allSettled(updateRequests)
      .then((results) =>
        results.map((result) => {
          if (result.status === 'fulfilled') {
            return unwrapResult(result.value as any);
          } else {
            throw new Error(result.reason);
          }
        })
      )
      .then((payloads) => {
        props.addToast({ text: 'Care option(s) successfully updated' });

        updateClient({ ...client, clientGroupTypes: payloads });
      })
      .catch((e) => {
        const errorMessage = e?.response?.data?.message || 'An error occurred';

        props.addToast({ text: errorMessage });
      });
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ disabled: formDisabled }),
      mapPropsToValues: () => initialState,
    }),
    [formDisabled, initialState]
  );

  type ConfigureCareOptionsMainPageProps = InferProps<typeof ConfigureCareOptionsMainPage.propTypes>;

  const FormikConfigureCareOptionsMainPage = useMemo(
    () => withFormik<ConfigureCareOptionsMainPageProps, ReducedConfigs>(formikOptions)(ConfigureCareOptionsMainPage),
    [formikOptions]
  );

  return (
    <ConfigureCareOptionsContext.Provider value={selectedTabValue}>
      <FormikConfigureCareOptionsMainPage
        client={client}
        onTabChanged={setSelectedTabValue}
        onTabClick={setSelectedTabValue}>
        <ConfigureCareOptionsForm client={client} />
      </FormikConfigureCareOptionsMainPage>
    </ConfigureCareOptionsContext.Provider>
  );
}

ConfigureCareOptions.propTypes = {
  addToast: PropTypes.func.isRequired,
  updateClientGroupType: PropTypes.func.isRequired,
};

type RouteProps = RouteComponentProps<{ id?: string }>;
type Props = InferProps<typeof ConfigureCareOptions.propTypes> & RouteProps;

const mapDispatchToProps = {
  addToast,
  updateClientGroupType,
};

export default connect(null, mapDispatchToProps)(ConfigureCareOptions);
